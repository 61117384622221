/** Color Variables **/
$colorMap: (
  primary: (
    base: #00D2A6,
    base-rgb: rgb(0,210,166),
    dark: rgba(0,210,166, 0.64)
  ),
  whiteColor: (
    base: #F5F5F5,
    a96: rgba(255,255,255, 0.96),
    a64: rgba(255,255,255, 0.64),
    a32: rgba(255,255,255, 0.32),
  ),
  darkGreyColor: (
    base: #9F9F9F
  ),
  darkColor: (
    a96: rgba(0,0,0, 0.96),
    a64:rgba(0,0,0, 0.64),
    a36: rgba(0,0,0, 0.36),
  ),
  elevation: (
    a00: #0E1010,
    a01: #1C1E1E,
    a02: #262828,
    a03: #303131,
    a04: #393B3B
  ),
  gradient: (
    light: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%),
    dark: linear-gradient(179.65deg, #262828 0.28%, #1C1E1E 84.4%, #0E1010 99.67%)
  ),
  warning: (
    base: #FFB950
  ),
  critical: (
    base: #F83A61
  ),
  information: (
    base: #2E8CE4
  ),
  glass: (
    thick: rgba(14, 16, 16, 0.8),
    medium: rgba(28, 30, 30, 0.64),
    thin: rgba(14, 16, 16, 0.24)
  ),
  utility: (
    border: rgba(14, 16, 16, 0.16),
    outline: rgba(255, 255, 255, 0.08),
    separator: rgba(255, 255, 255, 0.12)
  ),
  background: (
    base: #0e0e0e
  )
);

@function getColor($color, $variant) {
  @return map-get(map-get($colorMap, $color), $variant);
}

@mixin color($color, $variant) {
  color: getColor($color, $variant);
}

/** Spacing Variables **/
$spaceMap: (
  xxxxs: 2px,
  xxxs: 4px,
  xxs: 8px,
  xs: 12px,
  s: 16px,
  m: 24px,
  l: 32px,
  xl: 48px,
  xxl: 64px,
  xxxl: 96px,
  xxxxl: 128px,
  xxxxxl: 192px,
  xxxxxxl: 258px,
);

@function getSpace($size) {
  @return map-get($spaceMap, $size); 
}

/** Text Variables **/
$textSizeMap: (
  largeTitle: 34px,
  title1: 32px,
  title2: 24px,
  title3: 20px,
  heading1: 16px,
  heading2: 14px,
  body1: 16px,
  body2: 14px,
  button: 13px,
  caption1: 12px,
  caption2: 11px,
);

$textLineHeightMap: (
  largeTitle: 42px,
  title1: 36px,
  title2: 30px,
  title3: 28px,
  heading1: 24px,
  heading2: 20px,
  body1: 24px,
  body2: 20px,
  button: 18px,
  caption1: 16px,
  caption2: 14px,
);

$textLetterSpacingMap: (
  largeTitle: 0px,
  title1: 0.8px,
  title2: 0.2px,
  title3: -0.4px,
  heading1: -0.2px,
  heading2: -0.2px,
  body1: -0.4px,
  body2: -0.3px,
  button: 0.8px,
  caption1: -0.2px,
  caption2: 0.4px,
);

$textWeightMap: (
  largeTitle: 400,
  title1: 600,
  title2: 600,
  title3: 500,
  heading1: 600,
  heading2: 600,
  body1: 400,
  body2: 500,
  button: 600,
  caption1: 400,
  caption2: 500,
);

$textTextTransformMap: (
  largeTitle: none,
  title1: uppercase,
  title2: uppercase,
  title3: none,
  heading1: none,
  heading2: none,
  body1: none,
  body2: none,
  button: uppercase,
  caption1: none,
  caption2: uppercase,
);

@mixin font($type: 'body') {
  font-size: map-get($textSizeMap, $type);
  font-weight: map-get($textWeightMap, $type);
  line-height: map-get($textLineHeightMap, $type);
  letter-spacing: map-get($textLetterSpacingMap, $type);
  text-transform: map-get($textTextTransformMap, $type);
}