@import "../../theme/variables.scss";

.grid {
  margin-left: getSpace("s");
  margin-right: getSpace("s");
  margin-top: getSpace("xl");
}

.headerRow {
  border-bottom: 1px solid getColor("utility", "separator") !important;
  padding-bottom: getSpace("s");
}

.wallCardRow {
  margin-top: getSpace("xs");
}

.title1 {
  text-transform: initial;
}
